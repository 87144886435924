.inRangeBg {
  background-color: theme(colors.nuowoOrange);
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  opacity: 1;
  animation-name: showOnScroll;
  animation-duration: 2s;
}

.inRange {
  opacity: 1;
  animation-name: showContentOnScroll;
  animation-duration: 3s;
}

.outRangeBg {
  opacity: 0.5;
  animation-name: hideOnScroll;
  animation-duration: 1s;
}

.outRange {
  opacity: 0.5;
  animation-name: hideContentOnScroll;
  animation-duration: 0.5s;
}

@keyframes showContentOnScroll {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideContentOnScroll {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes showOnScroll {
  from {
    opacity: 0.5;
  }
  to {
    background-color: theme(colors.nuowoOrange);
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    opacity: 1;
  }
}

@keyframes hideOnScroll {
  from {
    background-color: theme(colors.nuowoOrange);
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

/* .content div h3 {
  animation-name: showOnScroll;
  animation-duration: 20s;
} */
