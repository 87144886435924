.image-container {
  position: relative;
  width: 300px; /* Set the width of the image container */
  height: 250px; /* Set the height of the image container */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure the image covers the container */
}

.text-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100px; /* Set a max height for the scrollable text */
  padding: 10px;
  opacity: 0.7;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

#topRow {
  top: 0;
  position: absolute;
}

#bottomRow {
  bottom: 150px;
  position: absolute;
}

.slider {
  width: 100%;
}

.slider .list {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}

.slider .list .item {
  width: var(--width);
  height: calc(var(--height) / 2);
  margin-top: calc(var(--randomNumber) * 2rem) !important;
  position: absolute;
  left: 100%;
  animation: autoRun var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) *
      (var(--quantity) - var(--position) - 1)
  ) !important;
  transition: filter 0.5s;
  transition: autoEun 1s ease-in-out;
}

.slider .list .item img {
  width: 100%;
}

@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}

.slider:active .item {
  animation-play-state: paused;
  filter: grayscale(1);
}

.slider .item:active {
  filter: grayscale(0);
}
