.landingPage {
  position: relative;
}

.orageIcon {
  color: theme(colors.nuowoOrange);
}

.lazyText {
  opacity: 0;
  transform: translateY(50%);
  animation: fade-in 0.5s ease-out forwards;
}

.lazy1sLoad {
  opacity: 0;
  transform: translateY(50%);
  animation: fade-in 1s ease-out forwards;
}

.lazy15sLoad {
  opacity: 0;
  transform: translateY(50%);
  animation: fade-in 1.5s ease-out forwards;
}

@keyframes fade-in {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
