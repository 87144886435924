.topNav {
  position: fixed;
  top: 0;
  z-index: 999;
  /* background-color: rgba(225, 225, 225, 0.1); */
  background-color: #fc73421d;
  width: 100%;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.applyNow {
  background-color: theme(colors.nuowoOrange);
  color: theme(colors.nuowoDarkTeal);
}
